import { env } from 'config';
import { RequisitesDistribution, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/requisites-distribution`;

const getByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<RequisitesDistribution>> =>
    (await apiUtils.getWithQueryParams(url, params)).data;

const getCache = getByUrl(`${url}/cache`);

export const requisitesDistributionApi = {
  getCache,
};
