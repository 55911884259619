import React, { Fragment, ReactElement } from 'react';

import { DataWrapper, DataWrapperIgnoreState, Pagination } from 'components';
import { UsePartialQueryResult, useQueryPagination } from 'hooks';
import { PaginatedData } from 'types';

type Props = {
  queryResult: UsePartialQueryResult<PaginatedData<unknown>, unknown>;
  children: ReactElement | ReactElement[];
  ignoreState?: DataWrapperIgnoreState;
};

export const PaginationWrapper: React.FC<Props> = ({
  queryResult,
  children,
  ignoreState,
}: Props) => {
  const { pagination, setPagination } = useQueryPagination();

  return (
    <Fragment>
      <DataWrapper
        queryResult={queryResult}
        paginated
        ignoreState={{ ...ignoreState }}
      >
        <Fragment>{children}</Fragment>
      </DataWrapper>
      <Pagination
        paginationOptions={pagination}
        queryResult={queryResult}
        onChange={setPagination}
      />
    </Fragment>
  );
};
