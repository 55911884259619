export enum OrderAutomationReviewedStatusDetails {
  Duplicate = 'duplicate',
  OwnTransfer = 'own_transfer',
}

export const OrderAutomationStatusDetails = {
  ...OrderAutomationReviewedStatusDetails,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OrderAutomationStatusDetails = OrderAutomationReviewedStatusDetails;
