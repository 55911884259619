import { env } from 'config';
import { AssetBalanceHistory, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/asset-balance-history`;

const getByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<AssetBalanceHistory>> =>
    (await apiUtils.getWithQueryParams(url, params)).data;

const getAll = getByUrl(url);

const getMy = getByUrl(`${url}/my`);

const getAllMerchants = getByUrl(`${url}/merchants`);

export const assetBalanceHistoryApi = {
  getAll,
  getMy,
  getAllMerchants,
};
