import { FormControl, Typography } from '@mui/material';
import {
  FieldArray,
  Form,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from 'formik';
import { filter, keys, map, pick } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikCheckbox,
  FormikMultiSelect,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
} from 'components';
import { P2PProviderType } from 'enums';
import { useCurrencies, usePrevious, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProviderDto } from 'types';
import { tradeMethodsUtils } from 'utils';

type Values = P2PProviderDto;

type Props = DialogProps<Values>;

export const P2PProviderDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { tradeMethods, banks, paymentTypes, fiatCurrencies } =
    useUserContext();
  const {
    assetCurrenciesOptions,
    fiatCurrenciesOptions,
    defaultAssetCurrency,
    getCurrencyExchangeOptions,
    getAssetCurrencyExchangeOptions,
  } = useCurrencies();

  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const prevOpen = usePrevious(open);

  const title = useMemo(
    () =>
      data ? t('details_dialog.edit_title') : t('details_dialog.create_title'),
    [t, data],
  );

  const providerTypeOptions = useMemo(
    () =>
      map([P2PProviderType.InternalLike], (type) => ({
        value: type,
        label: t(`types.${type}`),
      })),
    [t],
  );

  const getInitialValues = useCallback(
    (): Values => ({
      name: undefined,
      type: P2PProviderType.InternalLike,
      withdrawalFee: 0,
      assetCurrencyId: '',
      fiatCurrencyId: '',
      currencyExchangeId: '',
      payinConfig: {
        url: undefined,
        credentials: {
          apiKey: undefined,
          apiToken: undefined,
          signatureKey: undefined,
          password: undefined,
          apiSecret: undefined,
          apiPublic: undefined,
          apiPrivate: undefined,
          host2host: undefined,
        },
        enabled: false,
        pollingEnabled: false,
        responseTimeout: null,
        expectedFee: 0,
        tradeMethods: [],
        assetCurrencyExchangeId: null,
      },
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const getPayinConfigValidationSchema = useCallback(
    (
      schema: Yup.ObjectSchema<Values>,
      options?: { assetCurrencyExchangeIdRequired: boolean },
    ) =>
      schema.shape({
        url: Yup.string().required(tCommon('errors.required')),
        credentials: Yup.object(),
        enabled: Yup.boolean(),
        pollingEnabled: Yup.boolean(),
        responseTimeout: Yup.number().nullable(),
        expectedFee: Yup.number().required(tCommon('errors.required')),
        tradeMethods: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string().required(tCommon('errors.required')),
            }),
          )
          .required(),
        ...(options?.assetCurrencyExchangeIdRequired
          ? {
              assetCurrencyExchangeId: Yup.string().required(
                tCommon('errors.required'),
              ),
            }
          : { assetCurrencyExchangeId: Yup.string().nullable().optional() }),
      }),
    [tCommon],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        type: Yup.string().oneOf(Object.values(P2PProviderType)),
        withdrawalFee: Yup.number().required(tCommon('errors.required')),
        assetCurrencyId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        currencyExchangeId: Yup.string().when('assetCurrencyId', {
          is: (assetCurrencyId: string) =>
            !!assetCurrencyId && assetCurrencyId === defaultAssetCurrency?.id,
          then: (schema) => schema.nullable().optional(),
          otherwise: (schema) => schema.required(tCommon('errors.required')),
        }),
        payinConfig: Yup.object().when('assetCurrencyId', {
          is: (assetCurrencyId: string) =>
            !!assetCurrencyId && assetCurrencyId === defaultAssetCurrency?.id,
          then: (schema) => getPayinConfigValidationSchema(schema),
          otherwise: (schema) =>
            getPayinConfigValidationSchema(schema, {
              assetCurrencyExchangeIdRequired: true,
            }),
        }),
      }),
    [tCommon, defaultAssetCurrency?.id, getPayinConfigValidationSchema],
  );

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<Values>) => {
      if (!data.ok) {
        data.data?.formikHelpers?.resetForm();
      }
      onClose(data);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({
        ok: true,
        data: {
          formikHelpers,
          values,
        },
      });
    },
    [handleClose],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const handleTradeMethodsChange = useCallback(
    (event: any) => {
      const newSelectedTradeMethods = event.target.value;

      formik.setFieldValue(
        'payinConfig.tradeMethods',
        newSelectedTradeMethods.map((id: string) => ({ id })),
      );
    },
    [formik],
  );

  useEffect(() => {
    if (open && !prevOpen && data) {
      const pickedData = pick(data, keys(initialValues));
      pickedData.payinConfig = data.payinConfig
        ? pick(data.payinConfig, keys(initialValues.payinConfig))
        : initialValues.payinConfig;
      setInitialValues(pickedData);
      // formik.validateForm(pickedData);
    }
  }, [data, initialValues, open, prevOpen]);

  const resetCurrencyRelatedFields = useCallback(() => {
    formik.setFieldValue('payinConfig.assetCurrencyExchangeId', '');
    formik.setFieldTouched('payinConfig.assetCurrencyExchangeId', true);
    formik.setFieldValue('currencyExchangeId', '');
    formik.setFieldTouched('currencyExchangeId', true);
  }, [formik]);

  const handleFiatCurrencyChange = useCallback(() => {
    formik.setFieldValue('payinConfig.tradeMethods', []);
    resetCurrencyRelatedFields();
  }, [formik, resetCurrencyRelatedFields]);

  const handleAssetCurrencyChange = useCallback(() => {
    resetCurrencyRelatedFields();
  }, [resetCurrencyRelatedFields]);

  const filteredTradeMethods = useMemo(() => {
    const fiatCurrencyId = formik.values.fiatCurrencyId;
    return filter(tradeMethods, {
      fiatCurrencyId,
    });
  }, [formik, tradeMethods]);

  const tradeMethodsOptions = useMemo(
    () =>
      tradeMethodsUtils.getTradeMethodOptions({
        tradeMethods: filteredTradeMethods,
        banks,
        paymentTypes,
        fiatCurrencies,
      }),
    [filteredTradeMethods, banks, paymentTypes, fiatCurrencies],
  );

  const isTradeMethodsSelectDisabled = useMemo(() => {
    const fiatCurrencyId = formik.values.fiatCurrencyId;
    return !fiatCurrencyId || !tradeMethodsOptions.length;
  }, [formik, tradeMethodsOptions]);

  const renderCredentials = useCallback(
    () => (
      <Fragment>
        <Typography>{t('fields.credentials')}</Typography>
        {formik.values.type === P2PProviderType.InternalLike && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name="payinConfig.credentials.apiKey"
            />
            <FormikTextField
              label={t('fields.signature_key')}
              name="payinConfig.credentials.signatureKey"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Trust2Pay && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
            <FormikTextField
              label={t('fields.password')}
              name="payinConfig.credentials.password"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.X2X && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
            <FormikTextField
              label={t('fields.password')}
              name="payinConfig.credentials.password"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Payscrow && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name="payinConfig.credentials.apiKey"
            />
            <FormikTextField
              label={t('fields.api_secret')}
              name="payinConfig.credentials.apiSecret"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Namba && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name="payinConfig.credentials.apiToken"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Paycraft && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_private')}
              name="payinConfig.credentials.apiPrivate"
            />
            <FormikTextField
              label={t('fields.api_public')}
              name="payinConfig.credentials.apiPublic"
            />
          </Fragment>
        )}
        {formik.values.type === P2PProviderType.Exwave && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name="payinConfig.credentials.apiToken"
            />
            <FormikTextField
              label={t('fields.host2host')}
              name="payinConfig.credentials.host2host"
            />
          </Fragment>
        )}
      </Fragment>
    ),
    [formik.values.type, t],
  );

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={title}
        data={{ values: formik.values, formikHelpers: formik }}
        okDisabled={!formik.isValid}
        onClose={handleClose}
        {...rest}
      >
        <Form>
          <FormControls>
            <FormikTextField label={t('fields.name')} name="name" required />
            <FormikSelect
              label={t('fields.type')}
              name="type"
              options={providerTypeOptions}
              disabled={!!data}
              required
            />
            <FormikNumericField
              label={t('fields.withdrawal_fee')}
              allowNegative={false}
              decimalScale={0}
              name="withdrawalFee"
              suffix={defaultAssetCurrency?.symbol}
              fullWidth
              required
            />
            <FormikSelect
              label={t('fields.fiatCurrencyId')}
              name="fiatCurrencyId"
              options={fiatCurrenciesOptions}
              onChange={handleFiatCurrencyChange}
              required
            />
            <FormikSelect
              label={t('fields.assetCurrencyId')}
              name="assetCurrencyId"
              options={assetCurrenciesOptions}
              onChange={handleAssetCurrencyChange}
              disabled={!!data}
              required
            />
            {formik.values.assetCurrencyId &&
              defaultAssetCurrency?.id !== formik.values.assetCurrencyId && (
                <FormikSelect
                  label={t('fields.currency_exchange')}
                  helperText={t('fields.currency_exchange_description')}
                  name="currencyExchangeId"
                  required
                  options={getCurrencyExchangeOptions({
                    fiatCurrencyId: formik.values.fiatCurrencyId,
                    assetCurrencyId: formik.values.assetCurrencyId,
                  })}
                />
              )}

            <Typography variant="h5">{tCommon('common.payin')}</Typography>
            <FormControl sx={{ alignSelf: 'flex-start' }}>
              <FormikCheckbox
                label={t('fields.enabled')}
                name="payinConfig.enabled"
              />
            </FormControl>
            <FormControl sx={{ alignSelf: 'flex-start' }}>
              <FormikCheckbox
                label={t('fields.polling_enabled')}
                name="payinConfig.pollingEnabled"
              />
            </FormControl>

            {formik.values.assetCurrencyId &&
              formik.values.assetCurrencyId !== defaultAssetCurrency?.id && (
                <FormikSelect
                  label={t('fields.withdrawal_currency_exchange')}
                  name="payinConfig.assetCurrencyExchangeId"
                  required
                  helperText={t(
                    'fields.withdrawal_currency_exchange_description',
                  )}
                  options={getAssetCurrencyExchangeOptions(
                    formik.values.assetCurrencyId,
                  )}
                  noneOption
                />
              )}
            <FormikTextField
              label={t('fields.url')}
              name="payinConfig.url"
              required
            />
            {renderCredentials()}
            <FormikNumericField
              label={t('fields.response_timeout')}
              name="payinConfig.responseTimeout"
              suffix={tCommon('suffixes.ms')}
              allowNegative={false}
              fullWidth
              helperText={t('fields.response_timeout_description')}
            />
            <FormikNumericField
              label={t('fields.expected_fee')}
              name="payinConfig.expectedFee"
              allowNegative={false}
              percentageSuffix
              fullWidth
              required
            />

            <FieldArray
              name="payinConfig.tradeMethods"
              render={() => {
                const multiSelectValue =
                  formik.values.payinConfig?.tradeMethods?.map(
                    ({ id }) => id,
                  ) || [];

                return (
                  <FormikMultiSelect
                    label={t('fields.trade_methods')}
                    name="payinConfig.tradeMethods"
                    options={tradeMethodsOptions}
                    value={multiSelectValue}
                    onChange={handleTradeMethodsChange}
                    disabled={isTradeMethodsSelectDisabled}
                  />
                );
              }}
            />
          </FormControls>
        </Form>
      </Dialog>
    </FormikProvider>
  );
};
