import { Cached as CachedIcon } from '@mui/icons-material';
import { CircularProgress, TablePagination, Tooltip } from '@mui/material';
import { isNil, isNumber } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataWrapper } from 'components';
import { DEFAULT_PAGINATION } from 'constants/pagination.constants';
import { PaginationCountAs } from 'enums';
import { UsePartialQueryResult } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PaginationOptions, PaginatedData } from 'types';

type Props = {
  queryResult: UsePartialQueryResult<PaginatedData<unknown>, unknown>;
  paginationOptions: PaginationOptions;
  onChange: (pagination: PaginationOptions) => void;
};

export const Pagination: React.FC<Props> = ({
  queryResult,
  paginationOptions,
  onChange,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.pagination',
  });

  const data = useMemo(
    () => queryResult?.queryResultCount?.data || queryResult.data,
    [queryResult],
  );

  const isLoading = useMemo(
    () =>
      queryResult.queryResultCount
        ? queryResult.queryResultCount?.isLoading ||
          queryResult.queryResultCount?.isRefetching
        : queryResult.isLoading || queryResult.isRefetching,
    [queryResult],
  );

  const { page, take } = useMemo(
    () => paginationOptions || DEFAULT_PAGINATION,
    [paginationOptions],
  );
  const countFetched = useMemo(
    () => isNumber(data?.count) && !isLoading,
    [data?.count, isLoading],
  );
  const pages = useMemo(() => data?.pages || 0, [data]);

  const count = useMemo(
    () => (countFetched ? data?.count || 0 : page * take + 1), // set fake count to enable next page button
    [data?.count, countFetched, page, take],
  );

  const isInvalidPage = useMemo(
    () => page > pages && countFetched,
    [countFetched, page, pages],
  );

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number,
    ) => onChange({ page: page + 1, take }),
    [take, onChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ page: 1, take: parseInt(event.target.value) });
    },
    [onChange],
  );

  const renderCount = useCallback(() => {
    if (queryResult?.paginationCountAs === PaginationCountAs.Cache) {
      return (
        <span className="tw-inline-flex tw-items-center tw-justify-center">
          <span>{`~${count} `}</span>
          <Tooltip
            title={
              <div>
                <div>{t('count_cached_1')}</div>
                <div>{t('count_cached_2')}</div>
              </div>
            }
          >
            <CachedIcon
              sx={{ ml: 0.5, cursor: 'pointer' }}
              fontSize="small"
              color="primary"
              onClick={() => queryResult.queryExactCount?.()}
            />
          </Tooltip>
        </span>
      );
    }
    return count;
  }, [count, queryResult, t]);

  const renderFrom = useCallback(() => {
    if (isNil(count)) {
      return null;
    }
    return (
      <span className="tw-inline-flex tw-items-center">
        <span className="tw-mr-1">{t('from')}</span>
        <DataWrapper
          queryResult={queryResult?.queryResultCount || queryResult}
          loadingView={
            <CircularProgress
              size={12}
              classes={{ root: 'tw-text-center tw-ml-1' }}
            />
          }
        >
          <Fragment>{renderCount()}</Fragment>
        </DataWrapper>
      </span>
    );
  }, [count, t, queryResult, renderCount]);

  const renderRange = useCallback(
    (pagination: { page: number }) => {
      const maxSize = (pagination.page + 1) * take;
      const startRow = maxSize - take + 1;
      const endRow = Math.min(maxSize, count);
      return (
        <span>
          {endRow ? `${startRow} - ${endRow} ` : `${endRow} `}
          {renderFrom()}
        </span>
      );
    },
    [count, renderFrom, take],
  );

  useEffect(() => {
    if (isInvalidPage) {
      handleChangePage(null, Math.max(pages - 1, 0));
    }
  }, [handleChangePage, isInvalidPage, pages]);

  if (isInvalidPage && page !== 1) {
    return null;
  }

  return (
    <TablePagination
      component={'div'}
      count={count}
      rowsPerPage={take}
      page={page - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      labelRowsPerPage={`${t('result')}:`}
      labelDisplayedRows={renderRange}
      nextIconButtonProps={{ color: 'secondary' }}
      SelectProps={{
        inputProps: {
          'aria-label': 'page number',
        },
      }}
      showFirstButton
      showLastButton={countFetched}
    />
  );
};
