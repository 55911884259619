import axios from 'axios';

import { env } from 'config';
import {
  QueryParams,
  PaginatedData,
  InternalWalletWithdrawalTransaction,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/internal-wallets-withdrawal-transactions`;

const getAllActivePaginated = async (
  params?: QueryParams,
): Promise<PaginatedData<InternalWalletWithdrawalTransaction>> =>
  (await apiUtils.getWithQueryParams(`${url}/active/paginated`, params)).data;

const getAllArchivePaginated = async (
  params?: QueryParams,
): Promise<PaginatedData<InternalWalletWithdrawalTransaction>> =>
  (await apiUtils.getWithQueryParams(`${url}/archive/paginated`, params)).data;

const statusToWaiting = async (
  id: string,
): Promise<InternalWalletWithdrawalTransaction> =>
  (await axios.post(`${url}/status-to-waiting/${id}`)).data;

const statusToError = async (
  id: string,
): Promise<InternalWalletWithdrawalTransaction> =>
  (await axios.post(`${url}/status-to-error/${id}`)).data;

export const internalWalletsWithdrawalTransactionsApi = {
  getAllActivePaginated,
  getAllArchivePaginated,
  statusToWaiting,
  statusToError,
};
