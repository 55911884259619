import { isNil, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { operatorsApi } from 'api';
import { FormikSelect, FormikSelectProps } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { Operator } from 'types';

type Props = {
  name: string;
  label?: string;
  operators?: Operator[];
} & Partial<FormikSelectProps>;

export const OperatorSelect: React.FC<Props> = ({
  name,
  operators,
  label,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.operator_select',
  });
  const queryResult = useQuery(QueryKey.Operators, operatorsApi.getAll, {
    enabled: isNil(operators),
  });

  const options = useMemo(
    () =>
      map(
        sortBy(
          operators || queryResult?.data,
          (operator) => operator.user?.name,
        ),
        (operator) => ({
          value: operator.id,
          label: operator.user?.name || '',
        }),
      ),
    [operators, queryResult],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
