import { PaginationOptions } from 'types';

import { useQueryData } from './use-query-data.hook';

export function useQueryPagination() {
  const { data, initialized, setData, reset } =
    useQueryData<PaginationOptions>('pagination');

  return {
    pagination: data,
    initialized,
    setPagination: setData,
    resetPagination: reset,
  };
}
