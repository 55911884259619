import { Form, Formik, FormikHelpers } from 'formik';
import { map, values } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { orderAutomationApi } from 'api';
import {
  CloseFormikDialogResult,
  CopyText,
  Dialog,
  DialogProps,
  FormControls,
  FormikSelect,
} from 'components';
import { OrderAutomationStatus, OrderAutomationStatusDetails } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { OrderAutomation } from 'types';
import { orderAutomationUtils } from 'utils';

type Props = DialogProps<OrderAutomation>;

type Values = Required<Pick<OrderAutomation, 'statusDetails'>>;

export const OrderAutomationReviewDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation.review_dialog',
  });
  const { t: tCommon } = useTranslation();

  const { role } = useUser();

  const details = useMemo(() => {
    if (data?.status === OrderAutomationStatus.NotFound) {
      return [
        OrderAutomationStatusDetails.Duplicate,
        OrderAutomationStatusDetails.OwnTransfer,
      ];
    }
    return [];
  }, [data?.status]);

  const initialValues = useMemo<Values>(
    () => ({
      statusDetails: details[0],
    }),
    [details],
  );

  const options = useMemo(
    () =>
      map(details, (detail) => ({
        value: detail,
        label: orderAutomationUtils.getStatusDetailsLabel(detail),
      })),
    [details],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        statusDetails: Yup.string()
          .oneOf(values(OrderAutomationStatusDetails))
          .required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const reviewMutation = useMutation(
    orderAutomationApi.reviewErrorAsRole(role),
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      formikHelpers.setSubmitting(true);
      reviewMutation.mutate(
        {
          id: data?.id!,
          ...values,
        },
        {
          onSuccess: () => {
            onClose({ ok: true });
            formikHelpers.resetForm();
          },
          onSettled: () => {
            formikHelpers.setSubmitting(false);
          },
        },
      );
    },
    [reviewMutation, data?.id, onClose],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={reviewMutation}
          onClose={handleClose}
          {...rest}
        >
          <Form>
            {data && (
              <Fragment>
                <div className="tw-mb-2">
                  <div className="tw-font-bold">{`${t('fields.id')}:`}</div>
                  <div>
                    <CopyText text={data?.id} />
                  </div>
                </div>
                <div className="tw-mb-8">
                  <div className="tw-font-bold">{`${t('fields.text')}:`}</div>
                  <div>
                    <CopyText text={data?.text} />
                  </div>
                </div>
              </Fragment>
            )}
            <FormControls>
              <FormikSelect
                name="statusDetails"
                label={t('fields.details')}
                options={options}
              />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
