import axios from 'axios';

import { env } from 'config';
import { Anomaly, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/anomalies`;

const getAllByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<Anomaly>> =>
    (await apiUtils.getWithQueryParams(url, params)).data;

const getAllNew = getAllByUrl(`${url}/new`);
const getAllReviewed = getAllByUrl(`${url}/reviewed`);

const search = async () => (await axios.post(`${url}/search`)).data;
const review = async (id: string): Promise<Anomaly> =>
  (await axios.post(`${url}/${id}/review`)).data;
const getLastSearchAt = async (): Promise<{ date: string }> =>
  (await axios.get(`${url}/last-search-at`)).data;

export const anomaliesApi = {
  getAllNew,
  getAllReviewed,
  getLastSearchAt,
  search,
  review,
};
