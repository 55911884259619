import { Send as SendIcon } from '@mui/icons-material';
import { find } from 'lodash';
import moment from 'moment';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi, usersApi, webhooksHistoryApi } from 'api';
import {
  ConfirmButton,
  CrudTable,
  CrudTableAction,
  CrudTableActionType,
  DataGridColumnDefinition,
  QueryFilters,
  WebhookStatusLabel,
} from 'components';
import { FilterDefinitionType, QueryKey, WebhookStatus } from 'enums';
import { useMutation, usePartialQuery, useQueryFilters, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  WebhookFilters,
  FilterDefinition,
  Webhook,
  WebhookHistory,
} from 'types';
import { filtersUtils, webhooksUtils } from 'utils';

export const ArchiveTab: React.FC = () => {
  useQueryFilters<WebhookFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.webhooks',
  });
  const { t: tCommon } = useTranslation();
  const { role, isAdmin, isMerchant } = useUser();

  const { filters } = useQueryFilters<WebhookFilters>();

  const queryResult = usePartialQuery(
    QueryKey.Webhooks,
    webhooksHistoryApi.getAllPaginatedAsRole(role),
    { useCachedCount: true },
  );

  const queryResultShops = useQuery(
    QueryKey.Shops,
    isMerchant ? shopsApi.getAllMy : shopsApi.getAll,
  );
  const queryResultMerchants = useQuery(
    QueryKey.UsersMerchants,
    usersApi.getAllMerchants,
    { enabled: isAdmin },
  );

  const { mutate: resend } = useMutation(
    webhooksHistoryApi.resendAsRole(role),
    { notifierType: 'save' },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Webhook>[] => [
      ...webhooksUtils.getCommonColumns(),
      {
        header: t('columns.status'),
        valueGetter: (item) => <WebhookStatusLabel status={item.status} />,
      },
    ],
    [t],
  );

  const filtersDefinitions: FilterDefinition<WebhookFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: queryResultMerchants.data,
        getDisplayName: (merchantId: string) =>
          find(queryResultMerchants.data, { id: merchantId })?.name,
        hidden: isMerchant,
      },
      {
        label: tCommon('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: tCommon('filters.payin_order_id'),
        name: 'orderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.payout_order_id'),
        name: 'payoutOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: WebhookStatus,
        getDisplayName: webhooksUtils.getStatusLabel,
      },
    ],
    [isMerchant, queryResultMerchants.data, queryResultShops.data, tCommon],
  );

  const handleConfirmResend = useCallback(
    (webhook: WebhookHistory) => {
      resend(webhook.id);
    },
    [resend],
  );

  const actions = useMemo(
    (): CrudTableAction<WebhookHistory>[] => [
      {
        type: CrudTableActionType.Custom,
        render: (item) => (
          <ConfirmButton
            dialog={{
              children: t('messages.resend_confirm'),
            }}
            onConfirm={() => handleConfirmResend(item)}
          >
            <SendIcon color="primary" />
          </ConfirmButton>
        ),
      },
    ],
    [handleConfirmResend, t],
  );

  return (
    <Fragment>
      <QueryFilters
        filtersDefinitions={[
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
        ]}
      />
      <CrudTable
        queryResult={queryResult}
        columns={columns}
        actions={actions}
        paginated
      />
    </Fragment>
  );
};
