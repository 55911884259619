import { isNil, omit } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query';

import { PaginationCountAs, PaginationDataAs } from 'enums';
import { useQueryFilters, useQueryPagination } from 'hooks';
import { QueryParams, PaginatedData } from 'types';

export type UsePartialQueryResult<TData, TError> = UseQueryResult<
  TData,
  TError
> & {
  paginationCountAs?: PaginationCountAs;
  queryResultCount?: UseQueryResult<PaginatedData<any>>;
  queryExactCount?: () => void;
};

export function usePartialQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  queryFn: (params: QueryParams) => TQueryFnData | Promise<TQueryFnData>,
  optionsProp?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData>,
    'queryKey' | 'queryFn'
  > & {
    useCachedCount?: boolean;
  },
): UsePartialQueryResult<TData, TError> {
  const { pagination, initialized: paginationInitialized } =
    useQueryPagination();
  const { filtersQuery, initialized: queryInitialized } = useQueryFilters();

  const options = useMemo(
    () => ({
      ...optionsProp,
      ...((!queryInitialized || !paginationInitialized) && { enabled: false }),
    }),
    [optionsProp, queryInitialized, paginationInitialized],
  );

  const queryOptions = useMemo(
    () =>
      omit(options, ['useCachedCount']) as Omit<
        UseQueryOptions<TQueryFnData, TError, TData>,
        'queryKey' | 'queryFn'
      >,
    [options],
  );

  const [shouldQueryExactPagination, setShouldQueryExactPagination] =
    useState(false);

  const [paginationCountAs, setPaginationCountAs] = useState(
    PaginationCountAs.Cache,
  );

  const [countResponse, setCountResponse] = useState<Pick<
    PaginatedData<any>,
    'count' | 'pages'
  > | null>(null);

  const queryPagination = useMemo(() => {
    const copy = { ...pagination };
    if (options?.useCachedCount) {
      copy.countAs = PaginationCountAs.None;
    }
    return copy;
  }, [pagination, options?.useCachedCount]);

  const queryCountPagination = useMemo(() => {
    const copy = { ...pagination };
    if (options?.useCachedCount) {
      copy.dataAs = PaginationDataAs.None;
      copy.countAs = paginationCountAs;
    }
    return copy;
  }, [pagination, options?.useCachedCount, paginationCountAs]);

  const queryResult = useQuery(
    [queryKey, filtersQuery, pagination],
    ({ signal }) =>
      queryFn({ filters: filtersQuery, ...queryPagination, signal }),
    queryOptions,
  );

  const queryResultCount = useQuery(
    [
      queryKey,
      'count',
      filtersQuery,
      pagination?.take,
      shouldQueryExactPagination,
    ],
    ({ signal }) =>
      queryFn({ filters: filtersQuery, ...queryCountPagination, signal }),
    {
      enabled:
        !!options?.useCachedCount && queryInitialized && paginationInitialized,
      onSuccess: (response: any) => {
        setCountResponse({
          count: response.count,
          pages: response.pages,
        });
        setPaginationCountAs(response.countAs);
      },
    },
  );

  const queryExactCount = useCallback(() => {
    setPaginationCountAs(PaginationCountAs.Exact);
    setShouldQueryExactPagination(true);
  }, []);

  return {
    ...queryResult,
    data: isNil(queryResult.data)
      ? undefined
      : ({
          ...queryResult.data,
          ...countResponse,
        } as unknown as any), // TODO: fix typings
    ...(options?.useCachedCount && {
      paginationCountAs,
      queryResultCount,
      queryExactCount,
    }),
  };
}
