import axios from 'axios';

import { env } from 'config';
import {
  OrderAutomationFilterTypes,
  OrderAutomationStatusDetails,
  UserRole,
} from 'enums';
import { OrderAutomation, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

import { createCrudApi } from './crud.api';
const url = `${env.apiUrl}/order-automation`;

const getMessagesAsRole =
  (role: UserRole, messageType: OrderAutomationFilterTypes) =>
  async (params?: QueryParams): Promise<PaginatedData<OrderAutomation>> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/${messageType}`, params))
      .data;

const applyErrorTemplateAsRole =
  (role: UserRole) =>
  async (data: {
    parsingMethodId: string;
    orderAutomationId?: string;
    applyToAll?: boolean;
  }) =>
    (await axios.post(`${url}/${role}/template-error/apply`, data)).data;

const reviewErrorAsRole =
  (role: UserRole) =>
  async ({
    id,
    statusDetails,
  }: {
    id: string;
    statusDetails: OrderAutomationStatusDetails;
  }) =>
    (await axios.post(`${url}/${role}/${id}/review`, { statusDetails })).data;

export const orderAutomationApi = {
  ...createCrudApi<OrderAutomation>('/order-automation'),
  getMessagesAsRole,
  applyErrorTemplateAsRole,
  reviewErrorAsRole,
};
