import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { RequisitesGroup } from 'types';

import { createCrudApi } from './crud.api';

const path = '/requisites-groups';
const url = `${env.apiUrl}${path}`;

const updateOrderAutomationAsRole =
  (role: UserRole) =>
  async ({
    id,
    data,
  }: {
    id: string;
    data: Pick<RequisitesGroup, 'orderAutomationType'>;
  }): Promise<RequisitesGroup> =>
    (await axios.patch(`${url}/${role}/${id}/order-automation`, data)).data;

const getAllTraderAsRole =
  (role: UserRole) =>
  async (traderId: string): Promise<RequisitesGroup[]> =>
    (await axios.get(`${url}/${role}/trader/${traderId}`)).data;

export const requisitesGroupApi = {
  ...createCrudApi<RequisitesGroup>(path),
  updateOrderAutomationAsRole,
  getAllTraderAsRole,
};
