import { env } from 'config';
import { UserRole } from 'enums';
import { Merchant, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/merchants`;

const getAllAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Merchant[]> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}`, params)).data;

export const merchantsApi = {
  getAllAsRole,
};
