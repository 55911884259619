import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { Asset, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/assets`;

const getAll = async (params?: Partial<QueryParams>): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(url, params)).data;

const getAllMy = async (params?: Partial<QueryParams>): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(`${url}/my`, params)).data;

const getAllAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}`, params)).data;

const getAllMerchantsAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/merchants`, params))
      .data;

const getAllTradersAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Asset[]> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/traders`, params)).data;

const getAllPlatform = async (
  params?: Partial<QueryParams>,
): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(`${url}/platform`, params)).data;

const getMerchantAllMy = async (
  params?: Partial<QueryParams>,
): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(`${url}/merchant/my`, params)).data;

const getAllShops = async (params?: Partial<QueryParams>): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(`${url}/shops`, params)).data;

const withdrawInsuranceDeposit = async (
  assetCurrencyId: string,
): Promise<any> =>
  (await axios.post(`${url}/insurance-deposit-withdrawal/${assetCurrencyId}`))
    .data;

const getAllP2PProviders = async (
  params?: Pick<QueryParams, 'filters'>,
): Promise<Asset[]> =>
  (await apiUtils.getWithQueryParams(`${url}/p2p-providers`, params)).data;

export const assetsApi = {
  getAll,
  getAllMy,
  getAllAsRole,
  getAllMerchantsAsRole,
  getAllTradersAsRole,
  getAllShops,
  getAllPlatform,
  getAllP2PProviders,
  getMerchantAllMy,
  withdrawInsuranceDeposit,
};
