import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { Shop } from 'types';

import { createCrudApi } from './crud.api';

const path = '/shop';
const url = `${env.apiUrl}${path}`;

const getAllAsRole = (role: UserRole) => async (): Promise<Shop[]> =>
  (await axios.get(`${url}/${role}`)).data;

const getAllMy = async (): Promise<Shop[]> =>
  (await axios.get(`${url}/my`)).data;

const create = async (shop: Partial<Shop>): Promise<Shop> =>
  (await axios.post(url, shop)).data;

const findOne = async (id: string): Promise<Shop> =>
  (await axios.get(`${url}/${id}`)).data;

const findOneAsRole =
  (role: UserRole) =>
  async (id: string): Promise<Shop> =>
    (await axios.get(`${url}/${role}/${id}`)).data;

const getReferrals = async (): Promise<Shop[]> =>
  (await axios.get(`${url}/referrals`)).data;

const updateAsRole =
  (role: UserRole) =>
  async ({ id, shop }: { id: string; shop: Partial<Shop> }): Promise<Shop> =>
    (await axios.patch(`${url}/${role}/${id}`, shop)).data;

const remove = async (shop: Shop): Promise<Shop> =>
  (await axios.delete(`${url}/${shop.id}`)).data;

export const shopsApi = {
  ...createCrudApi<Shop>(path),
  getAllAsRole,
  getAllMy,
  findOne,
  findOneAsRole,
  getReferrals,
  create,
  updateAsRole,
  remove,
};
