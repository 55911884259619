import {
  Cached as CachedIcon,
  CloudDownloadOutlined as CloudDownloadOutlinedIcon,
} from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { fiatCurrencyExchangeApi } from 'api';
import {
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { QueryKey, StatusCode } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FiatCurrencyExchange as FiatCurrencyExchangeType } from 'types';
import { currencyExchangeUtils, formatUtils } from 'utils';

import { FiatCurrencyExchangeDetailsDialog } from './FiatCurrencyExchangeDetailsDialog';

export const FiatCurrencyExchange: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies.fiat_currency_exchange',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => QueryKey.FiatCurrencyExchange, []);

  const { getFiatCurrencyCode } = useCurrencies();

  const queryResult = useQuery(queryKey, fiatCurrencyExchangeApi.getAll, {});

  const { mutate: remove } = useMutation(fiatCurrencyExchangeApi.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return tCommon('errors.in_use');
        }
      },
    },
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<FiatCurrencyExchangeType>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.source'),
        valueKey: 'source',
        valueFormatter:
          currencyExchangeUtils.getFiatCurrencyExchangeSourceLabel,
      },
      {
        header: t('fields.currency'),
        valueGetter: (item) =>
          `${getFiatCurrencyCode(item.fiatCurrencyId)} / ${getFiatCurrencyCode(
            item.crossFiatCurrencyId,
          )}`,
      },
      {
        header: t('fields.price'),
        valueGetter: (item) => (
          <div>
            <div>{formatUtils.formatMoney(item.price)}</div>
            <Typography variant="caption" color="textSecondary">
              {!!item.deviationPercentage && (
                <div>{`${t('fields.deviation')} ${formatUtils.formatNumber(
                  item.deviationPercentage,
                  { sign: true },
                )}%`}</div>
              )}
            </Typography>
            <Typography
              variant="caption"
              color={currencyExchangeUtils.getDateColor(
                item.date,
                item,
                queryResult.dataUpdatedAt,
              )}
            >
              <div className="tw-flex tw-items-center">
                <Tooltip title={t('fields.refresh_date')}>
                  <CloudDownloadOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                </Tooltip>
                {formatUtils.formatDate(
                  item.date,
                  undefined,
                  t('fields.no_calculate_date'),
                )}
              </div>
            </Typography>
            {item.refresh && (
              <Typography
                variant="caption"
                color={currencyExchangeUtils.getDateColor(
                  item.calculatedAt,
                  item,
                  queryResult.dataUpdatedAt,
                )}
              >
                <div className="tw-flex tw-items-center">
                  <Tooltip title={t('fields.calculate_date')}>
                    <CachedIcon fontSize="small" sx={{ mr: 1 }} />
                  </Tooltip>
                  {formatUtils.formatDate(
                    item.calculatedAt,
                    undefined,
                    t('fields.no_calculate_date'),
                  )}
                </div>
              </Typography>
            )}
          </div>
        ),
      },
      {
        header: t('fields.cross_price'),
        valueGetter: (item) => (
          <div>
            <div>{formatUtils.formatMoney(item.crossPrice)}</div>
            <Typography variant="caption" color="textSecondary">
              {!!item.priceSpreadPercentage && (
                <div>{`${t('fields.spread')} ${formatUtils.formatNumber(
                  item.priceSpreadPercentage,
                )}%`}</div>
              )}
            </Typography>
          </div>
        ),
      },
      {
        header: t('fields.enabled'),
        valueKey: 'enabled',
        valueFormatter: formatUtils.formatBoolean,
      },
      {
        header: t('fields.refresh'),
        valueKey: 'refresh',
        valueFormatter: formatUtils.formatBoolean,
      },
    ],
    [getFiatCurrencyCode, t, queryResult.dataUpdatedAt],
  );

  return (
    <CrudTable
      queryResult={queryResult}
      columns={columns}
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: FiatCurrencyExchangeDetailsDialog,
        },
        {
          type: CrudTableActionType.Remove,
          onRemove: (item, { close }) => remove(item.id, { onSuccess: close }),
        },
      ]}
    />
  );
};
