export enum OrderAutomationActiveStatus {
  New = 'new', // new message created
  Parsed = 'parsed', // message successfully parsed
  Retry = 'retry', // message not parsed, retry in cron
  WaitingConfirm = 'waiting_confirm', // when enabled in manual mode by trader
}

export enum OrderAutomationErrorStatus {
  NotFound = 'not_found', // order not found
  NoTradeMethod = 'no_trade_method', // no trade method found
  NoRequisites = 'no_requisites', // wrong requisites token
  NoRequisitesGroup = 'no_requisites_group', // wrong requisites group token
  AutomationDisabled = 'automation_disabled', // wrong requisites token
  OrderDisputed = 'order_disputed', // order moved to dispute
  OrderCancelled = 'order_cancelled', // order moved to cancel
  MultipleOrders = 'multiple_orders', // multiple orders fits to message
  NoSource = 'no_source', // no source found by data from client
  NoSourceInRequest = 'no_source_in_request', // no source name came from client
  MultipleBanks = 'multiple_banks', // multiple banks found by source
  DifferentParseMethodTypes = 'different_parse_method_types', // different parse templates types fits for message
  InvalidTemplate = 'invalid_template', // invalid regexp saved as template
  DifferentParseMethodValues = 'different_parse_method_values', // different values for different parse templates
  DifferentParseMethodAmount = 'different_parse_method_amount', // old status, not used
  NotConfirmed = 'not_confirmed', // old status, not used
}

export enum OrderAutomationTemplateStatus {
  TemplateError = 'template_error', // message not parsed, lifetime finished
  TemplateErrorHandled = 'template_error_handled', // message not parsed, lifetime finished, operator handled this error by adding template
}

export enum OrderAutomationSuccessStatus {
  Success = 'success', // message parsed, order found, order completed
}

export enum OrderAutomationReviewedStatus {
  Reviewed = 'reviewed', // message parsed, went to error and it marked as error reviewed
}

export enum OrderAutomationTechnicalStatus {
  Payout = 'payout',
  Technical = 'technical',
  Spam = 'spam', // if message parsed with spam template
  SpamSource = 'spam_source', // if message has spam source
  Blocked = 'blocked', // if bank blocked user account
}

export const OrderAutomationStatus = {
  ...OrderAutomationActiveStatus,
  ...OrderAutomationErrorStatus,
  ...OrderAutomationSuccessStatus,
  ...OrderAutomationTechnicalStatus,
  ...OrderAutomationTemplateStatus,
  ...OrderAutomationReviewedStatus,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OrderAutomationStatus =
  | OrderAutomationActiveStatus
  | OrderAutomationErrorStatus
  | OrderAutomationSuccessStatus
  | OrderAutomationTechnicalStatus
  | OrderAutomationTemplateStatus
  | OrderAutomationReviewedStatus;
